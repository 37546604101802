import React, { useState, useEffect, useRef } from "react";
import {
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Dialog,
  Box,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import useScreensize from "../hooks/useScreensize";
import useValues from "@bit/wamedia.tasso.use-values";
import API from "../utils/API";
import { Redirect } from "react-router-dom";
import Moment from "react-moment";
import { blueGrey, red } from "@material-ui/core/colors";
import "moment/locale/de";
import CountDown from "./CountDown";
import ClockAlertIcon from "mdi-react/ClockAlertOutlineIcon";
import AlertIcon from "mdi-react/AlertOutlineIcon";
import { useDispatch, useSelector } from "react-redux";
import * as reservationActions from "../store/actions/reservationActions";
import * as savedBookingForPaymentActions from "../store/actions/savedBookingForPaymentActions";
import BookingPayment from "./BookingPayment";
import BookingShopProducts from "./BookingShopProducts";
import BookingForm from "./BookingForm";
import * as Scroll from "react-scroll";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import LegitimationQuestions from "./LegitimationQuestions";
import LoadingIcon from "mdi-react/LoadingIcon";

const scroll = Scroll.animateScroll;
const Element = Scroll.Element;

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "sticky",
    color: "#fff",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  header: {
    background: blueGrey[50],
  },
  privacyText: {
    fontSize: 12,
  },
  privacyLabel: {
    fontSize: 14,
    position: "relative",
    top: -2,
    fontWeight: 700,
  },
  date: {
    fontSize: "1.4rem",
    whiteSpace: "nowrap",
  },
  lateIcon: {
    color: red[500],
    width: 100,
    height: 100,
  },
  lateHint: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  lateBtn: {
    color: "#fff",
  },
  loadingIconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    left: 0,
    top: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 9999,
    backgroundColor: "rgba(255, 255, 255, 0.6)",
  },
  loadingIcon: {
    color: blueGrey[500],
    width: 40,
    height: 40,
    animation: "spin 1s linear infinite"
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialValues = {
  NumberPlate: "",
  Email: "",
  FirstName: "",
  LastName: "",
  Street: "",
  StreetNumber: "",
  location: 0,
  Wsh: false,
  Sonderabfall: false,
  KidsArea: false,
  Zip: "",
  City: "",
  PhoneNumber: "",
};

const Booking = ({ slot, handleClose, open, preset }) => {
  const classes = useStyles();
  const { xs, sm, md, lg, xl } = useScreensize();
  const { values, setValues, handleChangeValue } = useValues(initialValues);
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [success, setSuccess] = useState(null);
  const [tooLate, setTooLate] = useState(false);
  const [error, setError] = useState(null);
  const [paymentKind, setPaymentKind] = useState(null);
  const [kidsAreaAvailable, setKidsAreaAvailable] = useState(false);
  const reservation = useSelector(
    (state) => state.reservation.reservations[slot.id]
  );
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.location.locations);
  const businessUnit = useSelector((state) => state.businessUnit);
  const shopProducts = useSelector((state) => state.shopProducts);
  const [cart, setCart] = useState(null);
  const [step, setStep] = useState(1);
  const [acceptRevocation, setAcceptRevocation] = useState(false);
  const [acceptAgb, setAcceptAgb] = useState(false);
  const [additionalPersons, setAdditionalPersons] = useState([]);
  const [stripePromise, setStripePromise] = useState(null);
  const [availableSeats, setAvailableSeats] = useState(0);
  const [legitimationQuestionsSuccess, setLegitimationQuestionsSuccess] = useState(false);
  const isBookingRef = useRef(null);
  isBookingRef.current = isBooking;
  const [legitimationQuestionsProtocol, setLegitimationQuestionsProtocol] = useState([]);

  useEffect(() => {
    if (
      !businessUnit ||
      !businessUnit.Payment ||
      !businessUnit.Payment.StripePublicKey
    ) {
      return;
    }
    const str = loadStripe(businessUnit.Payment.StripePublicKey);
    setStripePromise(str);
  }, [businessUnit]);

  useEffect(() => {
    if (!preset) {
      return;
    }

    setValues(preset.values);
    setCart(preset.cart);
    setPaymentKind(preset.paymentKind);
    setAcceptPrivacy(true);
    setStep(3);
  }, [preset]);

  useEffect(() => {
    if (!open) {
      return;
    }

    setAvailableSeats(slot.AvailableSeats);

    if (businessUnit && businessUnit.KidsAreaMax) {
      API.get(`slots/${slot.id}/KidsArea/availability`)
        .then((result) => {
          const kidsAvResult = result.data ? result.data : null;
          // console.log(kidsAvResult);
          setKidsAreaAvailable(kidsAvResult.available);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    dispatch(reservationActions.addReservation(slot.id));

    if (shopProducts && shopProducts.length && (!preset || !preset.cart)) {
      setCart(shopProducts.map((m) => ({ product: m, quantity: 0 })));
    }
  }, [open]);

  useEffect(() => {
    scroll.scrollToTop({
      duration: 0,
      containerId: "dialog",
    });
  }, [step]);

  useEffect(() => {
    if (!cart || !cart.length) {
      return;
    }
    const p = cart.reduce(
      (a, b) => a + b.quantity * (b.product.ReservedPlaces || 1),
      0
    );

    if (p < 1) {
      setAdditionalPersons([]);
      return;
    }

    const currentAdditionalPersons = additionalPersons.slice();
    const newAdditionalPersons = Array.from({ length: p - 1 }).map(
      (item, i) => ({
        FirstName: currentAdditionalPersons[i]
          ? currentAdditionalPersons[i].FirstName
          : "",
        LastName: currentAdditionalPersons[i]
          ? currentAdditionalPersons[i].LastName
          : "",
      })
    );

    setAdditionalPersons(newAdditionalPersons);
  }, [cart]);

  const handlePayOne = () => {
    API.post("/bookings/shop/check-out/payone-link")
      .then((result) => {
        const payOneResult = result.data ? result.data : null;
        console.log(payOneResult);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSaveBookingForPayment = () => {
    const BookingData = {
      values,
      slot,
      cart,
      paymentKind,
      reservation,
    };

    dispatch(savedBookingForPaymentActions.save(BookingData));
  };

  const handleBookingWithShopOrder = (stripeIntentId, callback) => {
    if (isBooking) {
      return;
    }

    setIsBooking(true);
    const body = Object.assign({}, values);
    body.additionalPersons = additionalPersons;
    body.slot = slot.id;
    body.cart = cart;
    body.paymentKind = paymentKind;
    body.reservation = reservation.id;
    body.stripeIntentId = stripeIntentId;

    API.post("/bookings/shop/booking-with-shop-order", body)
      .then((result) => {
        const bookingResult = result.data ? result.data : null;
        if (bookingResult.booking && bookingResult.booking.uuid) {
          // dispatch(reservationActions.removeReservation(slot.id));
          callback(bookingResult);
        }
      })
      .catch((error) => {
        dispatch(reservationActions.removeReservation(slot.id));
        setError(
          error && error.response && error.response.data
            ? error.response.data.message
            : "Es ist ein Fehler bei der Buchung aufgetreten"
        );
        setIsBooking(false);
      });
  };

  const handleOnCompleted = (uuid) => {
    setIsBooking(false);
    dispatch(reservationActions.removeReservation(slot.id));
    setSuccess(uuid);
  };

  const handleBooking = () => {
    if (isBooking) {
      return;
    }
    setIsBooking(true);
    const body = Object.assign({}, values);
    body.slot = slot.id;
    body.reservation = reservation.id;
    body.LegitimationQuestions = legitimationQuestionsProtocol.join(" / ");

    API.post("bookings", body)
      .then((result) => {
        const bookingResult = result.data ? result.data : null;
        handleOnCompleted(bookingResult.uuid);
      })
      .catch((error) => {
        dispatch(reservationActions.removeReservation(slot.id));
        setError(
          error && error.response && error.response.data
            ? error.response.data.message
            : "Es ist ein Fehler bei der Buchung aufgetreten"
        );
        setIsBooking(false);
      });
  };

  const handleCloseClick = () => {
    // console.log("HANDLE CLOSE", isBooking, handleClose);
    if (isBooking) {
      return;
    }
    if (handleClose) {
      setValues(initialValues);
      setAcceptPrivacy(false);
      setAcceptRevocation(false);
      setAcceptAgb(false);
      setSuccess(false);
      setTooLate(false);
      setError(null);
      setPaymentKind(null);
      setStep(1);
      setCart([]);
      handleClose();
      setLegitimationQuestionsSuccess(false);
    }
  };

  const handleChangeRevocation = (event) => {
    setAcceptRevocation(event.target.checked);
  };

  const handleChangeAgb = (event) => {
    setAcceptAgb(event.target.checked);
  };

  const handleChangePrivacy = (event) => {
    setAcceptPrivacy(event.target.checked);
  };

  const handleTooLate = () => {
    // console.log("HANDLE TOO LATE 1", isBookingRef.current);
    if (isBookingRef.current) {
      return;
    }
    // console.log("HANDLE TOO LATE 2", isBookingRef.current);
    dispatch(reservationActions.removeReservation(slot.id));
    setTooLate(true);
  };

  const handleNext = () => {
    setStep((state) => state + 1);
  };

  const handlePrev = () => {
    setStep((state) => state - 1);
  };

  const goToProducts = () => {
    setStep(1);
  };

  if (success) {
    return <Redirect push to={"/buchung/" + success} />;
  }

  if (!businessUnit) {
    return null;
  }

  const isDisabled =
    !acceptPrivacy ||
    (businessUnit.InputFields.AdditionalPersons &&
      !additionalPersons.every((a) => a.FirstName && a.LastName)) ||
    (businessUnit.InputFields.PhoneNumber && !values.PhoneNumber) ||
    (businessUnit.InputFields.NumberPlate && !values.NumberPlate) ||
    (businessUnit.InputFields.Email && !values.Email) ||
    (businessUnit.InputFields.FirstName && !values.FirstName) ||
    (businessUnit.InputFields.LastName && !values.LastName) ||
    (businessUnit.InputFields.Street && !values.Street) ||
    (businessUnit.InputFields.StreetNumber && !values.StreetNumber) ||
    (businessUnit.InputFields.Location &&
      !values.location &&
      (!values.Zip || !values.City)) ||
    (businessUnit.Wsh &&
      businessUnit.Soa &&
      !values.Wsh &&
      !values.Sonderabfall);

  return (
    <>
      <Dialog
        open={open}
        size="md"
        fullWidth
        fullScreen={xs}
        TransitionComponent={xs ? Transition : undefined}
        PaperProps={{ id: "dialog" }}
      >
        <AppBar
          className={classes.appBar}
          color="secondary"
          style={{
            backgroundColor: businessUnit.ColorHeaderBackground,
            color: businessUnit.ColorHeaderText,
          }}
          elevation={2}
        >
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {slot.Info || businessUnit.BookingTitle || "Anmeldung"}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseClick}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Element name="top"></Element>
        <Box p={4} mb={1} className={classes.header}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Typography variant="caption" color="textSecondary">
                Ausgewählter Termin:
              </Typography>
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                className={classes.date}
              >
                <Moment
                  locale="de"
                  format="ddd, D. MMMM YYYY"
                  parse="YYYY-MM-DD"
                >
                  {slot.Date}
                </Moment>
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box mt={{ xs: 2, sm: 0 }}>
                <Typography variant="caption" className={classes.timeLabel}>
                  {businessUnit.SlotTitle || "Zeitfenster für die Anfahrt"}
                </Typography>
                <Typography variant="h4" className={classes.date}>
                  <Moment format="H:mm" parse="HH:mm:ss.SSS">
                    {slot.From}
                  </Moment>{" "}
                  -{" "}
                  <Moment format="H:mm" parse="HH:mm:ss.SSS">
                    {slot.To}
                  </Moment>{" "}
                  Uhr
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {error && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            minHeight={200}
            p={8}
          >
            <AlertIcon className={classes.lateIcon} />
            <Typography
              variant="body1"
              align="center"
              className={classes.lateHint}
            >
              {error}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseClick}
              className={classes.lateBtn}
              style={{ backgroundColor: businessUnit.ColorButtonBackground }}
            >
              Schließen
            </Button>
          </Box>
        )}
        {!error && tooLate && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            minHeight={200}
            p={8}
          >
            <ClockAlertIcon className={classes.lateIcon} />
            <Typography
              variant="body1"
              align="center"
              className={classes.lateHint}
            >
              Ihr Reservierungszeitraum ist abgelaufen.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseClick}
              className={classes.lateBtn}
              style={{ backgroundColor: businessUnit.ColorButtonBackground }}
            >
              Schließen
            </Button>
          </Box>
        )}
        {!error && !tooLate && !reservation && !success && (
          <Box
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="center"
            minHeight={200}
          >
            <CircularProgress />
          </Box>
        )}
        {!error && !tooLate && reservation && (
          <Box px={{ xs: 2, md: 4 }} pt={1} pb={3}>
            {open && (
              <CountDown
                start={reservation.created_at}
                handleTooLate={handleTooLate}
              />
            )}
            {Boolean(step === 1 && cart && cart.length) && (
              <BookingShopProducts
                cart={cart}
                setCart={setCart}
                onNext={handleNext}
                availableSeats={availableSeats}
              />
            )}
            {Boolean(businessUnit.LegitimationQuestions && !legitimationQuestionsSuccess) && (
              <LegitimationQuestions values={values} handleChangeValue={handleChangeValue} businessUnit={businessUnit} locations={locations} setLegitimationQuestionsSuccess={setLegitimationQuestionsSuccess} setLegitimationQuestionsProtocol={setLegitimationQuestionsProtocol} />
            )}
            {(!businessUnit.LegitimationQuestions || (businessUnit.LegitimationQuestions && legitimationQuestionsSuccess)) && (step === 2 || !cart || !cart.length) && (
              <BookingForm
                values={values}
                kidsAreaAvailable={kidsAreaAvailable}
                handleChangeValue={handleChangeValue}
                locations={locations}
                acceptPrivacy={acceptPrivacy}
                handleChangePrivacy={handleChangePrivacy}
                isDisabled={isDisabled}
                onClick={cart && cart.length ? handleNext : handleBooking}
                btnText={
                  cart && cart.length ? "Weiter" : "Anmeldung jetzt senden"
                }
                onPrev={cart && cart.length ? handlePrev : undefined}
                additionalPersons={additionalPersons}
                setAdditionalPersons={setAdditionalPersons}
              />
            )}
            {Boolean(
              step === 3 &&
              cart &&
              cart.length &&
              (!businessUnit.Payment ||
                !businessUnit.Payment.StripePublicKey ||
                stripePromise)
            ) && (
                <Elements stripe={stripePromise}>
                  <BookingPayment
                    sum={cart.reduce(
                      (a, b) => a + b.quantity * b.product.Price,
                      0
                    )}
                    isDisabled={isDisabled}
                    onPrev={handlePrev}
                    cart={cart}
                    goToProducts={goToProducts}
                    paymentKind={paymentKind}
                    setPaymentKind={setPaymentKind}
                    isBooking={isBooking}
                    setIsBooking={setIsBooking}
                    values={values}
                    onSaveBookingForPayment={handleSaveBookingForPayment}
                    onBookingWithShopOrder={handleBookingWithShopOrder}
                    stripePromise={stripePromise}
                    onCompleted={handleOnCompleted}
                    handleChangeRevocation={handleChangeRevocation}
                    acceptRevocation={acceptRevocation}
                    acceptAgb={acceptAgb}
                    handleChangeAgb={handleChangeAgb}
                    onPayOne={handlePayOne}
                    additionalPersons={additionalPersons}
                  />
                </Elements>
              )}
            <Button
              variant="outlined"
              fullWidth
              className={classes.btnCancel}
              onClick={handleCloseClick}
            >
              Abbrechen
            </Button>
          </Box>
        )}
        {isBooking && (
          <div className={classes.loadingIconWrapper}>
            <LoadingIcon className={classes.loadingIcon} />
          </div>
        )}
      </Dialog>
    </>
  );
};

export default Booking;
